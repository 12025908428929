<!-- =========================================================================================
  Author: Hazem Ashraf
  Author URL: https://www.linkedin.com/in/hazem-ashraf-1242169b/
========================================================================================== -->

<template>
  <div id="data-list">
 <vs-popup
      classContent="popup-example"
      title="Confirm Update"
      :active.sync="popupActive"
    >
    <validation-observer ref="observer" v-slot="{ invalid }">
        <form>
           <ValidationProvider
            name="password"
            rules="required|min:8"
            v-slot="{ errors }"
          >
      <vs-input
        :danger="!!errors[0]"
        :danger-text="errors[0]"
        type="password"
        name="password"
        autocomplete="off"
        icon-no-border
        icon="icon icon-lock"
        icon-pack="feather"
        label-placeholder="Password"
        v-model="password"
        class="w-full mt-6"
      />
           </ValidationProvider>

        <vs-button :disabled="invalid" type="filled" @click="updateCity()" class="mb-2 mt-5"
          >Confirm</vs-button
        >
        </form>
    </validation-observer>
    </vs-popup>
    <vx-card
      ref="filterCard"
      title="Updating a city"
      class="user-list-filters mb-8"
      collapse-action
      refresh-content-action
      @refresh="resetColFilters"
    >
     <validation-observer ref="observer" v-slot="{ invalid }">
      <form>
        <div class="vx-row">
          <div class="vx-col md:w-1/2 sm:w-1/2 w-full mb-2">
            <label class="text-sm opacity-75"
              ><span class="text-primary">*</span>City NAME</label
            >
  <validation-provider
                    v-slot="{ errors }"
                    name="city_name"
                    rules="required|min:2"
                  >
            <vs-input
              :danger="!!errors[0]"
              :danger-text="errors[0]"
              class="w-full"
              icon-pack="feather"
              icon="icon-terminal"
              icon-no-border
              v-model="city_name"
            />
  </validation-provider>
          </div>

        </div>

        <vs-button :disabled="invalid" type="filled" @click="openConfirm()" class="mb-2"
          >UPDATE</vs-button
        >
      </form>
     </validation-observer>
    </vx-card>
  </div>
</template>

<script>
import axios from "@/axios.js";
import { required, min } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode
} from "vee-validate";

setInteractionMode("eager");
// betterEager
extend("required", {
  ...required,
  message: "field can not be empty"
});

extend("min", {
  ...min,
  message: "field may not be less than {length} characters"
});

export default {
   components: {
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      city_name: null,
      popupActive: false,
      password: null,
    };
  },
  methods: {
    openConfirm(){
      this.popupActive = true;
    },
    updateCity() {
      this.$vs.loading();
this.popupActive = false;
      axios
        .put(`${process.env.VUE_APP_BASE_URI}/api/admin/cities/${this.$route.query.SID}` , {
          name : this.city_name , password : this.password
        })
        .then(response => {
           this.$vs.loading.close();
          if(response.status  === 200 ) {
               this.$vs.notify({
                  title: "Success",
                  text: "City is updated",
                  iconPack: "feather",
                  icon: "icon-alert-circle",
                  color: "success",

              });
              
              this.$router.push("../GetAll/City");
          }
          else{
                  this.$vs.notify({
                  title: "Error",
                  text: response.message,
                  iconPack: "feather",
                  icon: "icon-alert-circle",
                  color: "danger",
              });

          }
        })
         .catch(error => {
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'Error',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        });
        this.popupActive = false;
    },
    resetColFilters(card) {
      card.removeRefreshAnimation(100);
      this.city_name = null;
    },

  },
  mounted() {

      axios
      .get(
        `${process.env.VUE_APP_BASE_URI}/api/admin/cities/${this.$route.query.SID}`
      )
      .then(response => {
        this.city_name = response.data.response.data.name;

      });
      window.onpopstate = function() {
            return this.parent.$emit("popupClose", false);
    };
  },

};
</script>
